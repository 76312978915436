import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import getColors from './styles';
const Container = styled('div', { shouldForwardProp: propName => isPropValid(propName) })((props) => (Object.assign({ position: 'absolute', left: '-1px', top: '-40px', display: 'flex', alignItems: 'center', height: '40px', backgroundColor: props.colorValue, borderRadius: '12px', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, boxSizing: 'border-box', padding: '10px 20px', ':after': {
        position: 'absolute',
        right: '-18px',
        bottom: 0,
        content: '""',
        width: 0,
        height: 0,
        border: '1px solid',
        borderColor: `transparent transparent ${props.colorValue} transparent`,
        borderWidth: '0 20px 35px 0',
    }, ':before': {
        position: 'absolute',
        left: 0,
        bottom: '-9px',
        content: '""',
        width: 0,
        height: 0,
        border: '1px solid',
        borderColor: `${props.colorValue} transparent transparent transparent`,
        borderWidth: '9px 8px 0 0',
    } }, (props.customStyles || {}))));
const PortfolioBadge = ({ variant = 'info', isHovered = false, children = undefined, styles = {}, }) => {
    const theme = useTheme();
    const colors = useMemo(() => getColors(theme).byVariants[variant], [theme, variant]);
    return (_jsx(Container, { colorValue: isHovered ?
            colors.backgroundColorOnHover :
            colors.backgroundColor, style: {
            color: isHovered ?
                colors.textColorOnHover :
                colors.textColor,
        }, customStyles: styles, children: children }));
};
export default PortfolioBadge;
